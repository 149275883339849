<template>
  <v-container fill-height fluid class="overflow-hidden">
    <v-row justify="center" v-if="needsAcceptance && !loading">
      <v-dialog :value="true" max-width="290" persistent>
        <v-card>
          <v-alert type="error" class="headline" tile>Alert</v-alert>
          <!--          <v-card-title class="headline error white&#45;&#45;text">-->
          <!--            Loading large Data-->
          <!--          </v-card-title>-->
          <v-card-text
            >Selecting “All Associations” may result in a long load time. If you
            are seeking data for a specific Association, please select it from
            the list above. Alternatively, if you wish to begin loading data for
            all associations, click “Continue” below.
          </v-card-text>
          <v-card-actions>
            <v-btn dark color="secondary" @click="fetchChunks">
              Continue
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-overlay v-else-if="loading" color="primary">
      <div style="text-align:center;position:relative;">
        <v-progress-circular
          class="mr-auto"
          indeterminate
          size="64"
        ></v-progress-circular>
      </div>
      <h1 class="headline loading_text">Collecting {{ loadingVal }}</h1>
    </v-overlay>
    <template v-else>
      <v-btn
        v-if="$vuetify.breakpoint.smAndDown"
        color="primary"
        dark
        fixed
        bottom
        right
        fab
      >
        {{ report.index + 1 }}/{{ report.assCount }}
      </v-btn>
      <report @refresh="fetchReports(true)" />
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import storage from "@/storage";
import { lauderLarken } from "@/plugins/axios";
import { orderPayByDate, parsedClient } from "@/functions";
export default {
  name: "reports",
  data: () => ({
    loading: false,
    needsAcceptance: false,
    chunks: [],
    loadedChunks: []
  }),
  methods: {
    performReports(data) {
      const NowReports = [];
      let last = {};
      data.forEach(r => {
        if (
          r["Client_Associations"] !== last["Client_Associations"] ||
          r["Home Owner"] !== last["Home Owner"] ||
          r["Client Address"] !== last["Client Address"] ||
          r["Client Phone"] !== last["Client Phone"]
        ) {
          last = r;
          last.index = NowReports.length;
          NowReports.push({
            "Client Address": r["Client Address"],
            "Client Phone": r["Client Phone"],
            Client_Associations: r["Client_Associations"],
            "Collection Attorney": r["Collection Attorney"],
            "General Attorney": r["General Attorney"],
            "Home Owner": r["Home Owner"],
            "Litigation Paralegal": r["Litigation Paralegal"],
            "Management Company": r["Management Company"],
            "Pre-Litigation Paralegal": r["Pre-Litigation Paralegal"],
            Status: r["Status"],
            "Matter Number": r["Matter Number"],
            "Matter Reference": r["Matter Reference"],
            payments: []
          });
        }
        if (
          r["Payment Date"] ||
          r["Memo"] ||
          r["staff"] ||
          r["Payment Amount"]
        ) {
          NowReports[last.index].payments.push({
            Payment_Date: r["Payment Date"],
            Memo: r["Memo"],
            staff: r["staff"],
            "Payment Amount": r["Payment Amount"]
          });
          NowReports[last.index].payments = orderPayByDate(
            NowReports[last.index].payments
          );
        }
      });
      return NowReports;
    },
    async fetchChunks() {
      this.loading = true;
      this.loadedChunks = 0;
      for (let i = 0; i < this.chunks.length; i++) {
        try {
          const request = this.chunks[i];
          const sqlStringForRequest = `(''${request.join("'',''")}'')`;
          const { data } = await lauderLarken.getAllReports(
            sqlStringForRequest
          );
          const { a } = data;

          const NowReports = this.performReports(a);
          let lastClient = {};
          let asses = [];
          NowReports.forEach(clientReport => {
            if (
              clientReport["Client_Associations"] !==
              lastClient["Client_Associations"]
            ) {
              lastClient["Client_Associations"] =
                clientReport["Client_Associations"];
              lastClient.index = asses.length;
              asses.push({
                token: clientReport["Client_Associations"],
                asses: [{ ...clientReport }]
              });
            } else {
              asses[lastClient.index].asses.push({ ...clientReport });
            }
          });
          for (const ass of asses) {
            await storage.setCertainReports(ass.asses, ass.token, this.db);
          }
          this.loadedChunks = this.loadedChunks + 1;
        } catch (e) {
          console.log(e);
          i--;
        }
      }
      this.loading = false;
      this.needsAcceptance = false;
      this.chunks = [];
      this.loadedChunks = 0;
    },
    async waitForClients() {
      return new Promise((resolve, reject) => {
        let times = 0;
        const timer = setTimeout(async () => {
          times++;
          console.log(times);
          const clients = await storage.getAllClients(this.db);
          if (clients.length > 0) {
            clearTimeout(timer);
            resolve();
          }
          if (times > 15) {
            reject("TimeOut");
          }
        }, 1000);
      });
    },
    async fetchReports(forceUpdate = false) {
      console.log('--------fetchReports--------')
      this.loading = true;
      this.needsAcceptance = false;
     
      if (this.selected.AllAss) {
        const clients = await storage.getAllClients(this.db);
        console.log(clients)
        if (clients.length === 0) {
          await this.waitForClients();
          await this.fetchReports(forceUpdate);
          return;
        }
        const reports = await storage.getAllReports(this.db);
        const reportsNames = reports.map(x => x.asses[0].Client_Associations);
        const notFoundClients = forceUpdate
          ? clients
          : clients.filter(r => !reportsNames.includes(r.Client_Associations));
        if (notFoundClients.length === 0) {
          this.needsAcceptance = false;
          this.chunks = [];
          this.loading = false;
          return;
        } else {
          const requestChunks = [];
          let sum = 0;
          let nowChunk = [];
          notFoundClients.forEach((client, index) => {
            sum = sum + client.c;
            nowChunk.push(parsedClient(client.Client_Associations));
            if (sum >= 30000 || index === notFoundClients.length - 1) {
              requestChunks.push(nowChunk);
              nowChunk = [];
              sum = 0;
            }
          });
          this.needsAcceptance = true;
          this.chunks = requestChunks;
        }
        this.loading = false;
        return;
      } else {
        const searchForReport = await storage.getCertainReports(
          this.token,
          this.db
        );
        if (!searchForReport || searchForReport.length === 0 || forceUpdate) {
          try {
            const { data } = await lauderLarken.getReports(0, 0, this.token);
            console .log ("------------",data)
            const { a } = data;
            const asses = this.performReports(a);
            console .log ("------------",asses)
            await storage.setCertainReports(asses, this.token, this.db);
          } catch (e) {
            await this.fetchReports();
          }
        }
      }
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters({
      db: "laudergetdb",
      loginState: "laudergetLoginState",
      token: "customToken",
      selected: "lauderGetSelectedAss",
      mgmt: "isMgmt",
      report: "report"
    }),
    loadingVal() {
      if (this.needsAcceptance) {
        if (this.loading) {
          const chunksLength = this.chunks.length;
          return `${Math.round((this.loadedChunks / chunksLength) * 100)}%`;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  },
  watch: {
    selected: {
      immediate: true,
      handler() {
        this.fetchReports();
      }
    }
  },
  components: { report: () => import("@/components/report/report") }
};
</script>

<style scoped></style>
